<template>
	<div class="rule" v-html="content"></div>
</template>
<style lang="scss" scoped>
	.rule {
		padding: 4vw 3vw;
		font-size: 0.26rem;
		text-indent: 2em;
	}
</style>
<script>
	import {
		getRuleDetail
	} from "@/http/api";

	export default {
		components: {},
		data() {
			return {
				content: ''
			};
		},
		mounted() {
			this.getRuleDetail();
		},
		methods: {
			async getRuleDetail() {
				const res = await getRuleDetail({});
				this.content = res.long_contents;
			}
		},
		computed: {

		},
		filters: {

		}
	};
</script>
